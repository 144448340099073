import React from 'react'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import TwoColumn from "../../../components/two-column"
import CustomerJourney from '../../../components/customer-journey'

// import {Helmet} from 'react-helmet'

const oneUpBilling = {
  title: "One-up your billing with advanced options that increase flexibility.",
  description: [
    {
      text: "Advanced billing options from Billsby let you collect more recurring revenue, introduce business rules and logic and offer more flexible plans to your customers - all with no or limited software development."
    }
  ],
  buttons: [
    {
      isExternal: true,
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      buttonText: 'Sign up for free'
    },
    {
      scheduleButton: true,
      buttonColor: 'black',
    }
  ],
  sectionDevelopers: true,
  image: require('../../../images/billsby-advanced-plans.svg'),
  svg: true,
  isBanner: true,
  imagePosition: 'right',
  imageName: 'developers svg',
  backgroundColor: '#F3F5FB',
  textColor: '#252422',
}

const freeTrials = {
  title: "Let customers try your service for size with free trials.",
  list: [
    {
      title: 'Collect card details in advance',
      description: <>Start free trials with payment token collection, so that when it's time to pay, your system can automatically setup each customer.</>,
    },
    {
      title: 'Send automatic reminder emails',
      description: <>Stay compliant and do the right thing with automatic reminder emails. Got a different strategy? Turn them off if you'd like.</>,
    },
    {
      title: 'Provide self-service cancellation',
      description: <>Let customers cancel their trial in one click of our JavaScript widget - reducing your support burden and minimising unwanted charges.</>,
    }
  ],
  image: require('../../../images/billsby-presents.svg'),
  imageName: 'billsby-order-hierarchy',
  svg: true
}

const setupFees = {
  title: "Take money up-front with support for setup fees.",
  description: [
    {
      text: "Sometimes, there's a cost to getting customers started - whether it's a consultation, shipping out a physical product or just a desire to get an up-front commitment, with Billsby you can take setup fees at the same time as generating a subscription."
    },
    {
      text: "We'll automatically charge them alongside the first payment."
    }
  ],
  imagePosition: 'full-right',
  textColor: '#fff',
  backgroundColor: '#243f5c',
  backgroundImage: require('../../../images/green-bill@2x.png'),
  backgroundPosition: 'right',
  sectionProtection: true,
}

const enchanceMMR = {
  title: "Enhance your MRR with contracts and minimum terms.",
  list: [
    {
      title: 'Lock customers in without requiring pre-payment',
      description: <>Some customers are happy to commit for a discount, but they can't front all the fees up-front. Create minimum term plans to increase your locked-in revenue.</>,
    },
    {
      title: 'Rest assured that your minimum term will be enforced',
      description: <>When customers try to cancel before their term is up, we'll automatically bill them for the remainder of their contract.</>,
    },
    {
      title: 'Override minimum terms when it\'s the right thing to do',
      description: <>You can override minimum terms and help customers get out of their contract early in the Billsby control panel when it makes sense to do so.</>,
    }
  ],
  image: require('../../../images/billsby-compliance.svg'),
  imageName: 'billsby-order-hierarchy',
  svg: true,
  align: 'right'
}

const billingScenario = {
  title: "Got a billing scenario we haven't thought of? We've got it covered.",
  list: [
    {
      title: 'Bill anything to any stored card at any time with our one-time charge API',
      description: <>Just tell us the value of the charge and an invoice description and we'll do the rest - calculating taxes and generating an invoice in the usual way</>,
    },
    {
      title: 'Make a one-time charge at any time in Billsby Control Panel',
      description: <>No API? No problem. You can manually charge customers any time for any amount from within Billsby.</>,
    },
    {
      title: 'Flexibility that goes both ways',
      description: <>With payment gateways that support general credits, you can even deposit funds into customers payment tokens through our API or control panel.</>,
    }
  ],
  image: require('../../../images/billsby-complex-billing.svg'),
  imageName: 'billsby-order-hierarchy',
  svg: true
}



const TrialSetupContacts = () => {
  return (
    <Layout className="trials-setup">
      <SEO 
        title="Advanced Billing Options | Billsby | Powerful, customizable subscription billing software" 
        description="Free trials, setup fees and minimum terms can be combined to create advanced billing options as part of your subscription billing solution." 
        url="https://ww.billsby.com/product/creating/trials-setup-contracts"
      />
      
      <TwoColumn content={oneUpBilling}/>
      <CustomerJourney content={freeTrials} />
      <TwoColumn content={setupFees}/>
      <CustomerJourney content={enchanceMMR} />
      <CustomerJourney content={billingScenario} />
    </Layout>
  )
}

export default TrialSetupContacts